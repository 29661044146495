<template>
  <li class="field">
    <div class="title">프로그램명</div>
    <ul class="content">
      <li class="item">
        <input-text
            placeholder="내용을 입력하세요."
            v-model="menuName"
            @keyup="selectMenuList"
        />
        <div v-if="menuSearchList.length > 0" class="menu-container" style="">
          <ul class="list" style="padding: 0">
            <li
                v-for="(menu, menuIdx) in menuSearchList"
                :key="menuIdx"
                style="list-style: none; margin-bottom: 3px"
                @click="onMenuSearchClicked(menu.menuName)"
            >
              {{ menu.menuName }}
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </li>
</template>

<script>
import InputText from "../../../components/common/text/InputText.vue";
import { mapGetters } from "vuex";
export default {
  name: "SearchMenuList",
  components: {
    InputText
  },
  computed: {
    ...mapGetters(["menuListByKeyword"]),
  },
  data () {
    return {
      menuSearchList: [],
      menuName: ''
    };
  },
  methods: {
    onMenuSearchClicked(name) {
      this.menuName = name;
      this.menuSearchList = [];
    },
    selectMenuList()  {
      this.menuSearchList = this.menuListByKeyword(this.menuName);
    },
    refresh() {
      this.menuSearchList = [];
    }
  }
};
</script>

<style scoped>
.menu-container {
  background-color: white;
  position: fixed;
  width: 8rem;
  border: 1px #e0e0e0 solid;
  border-radius: 4px;
  padding-left: 10px;
  padding-top: 2px;
  z-index: 999;
}
</style>